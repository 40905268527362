import React from 'react';
import './style.less';
import {Col, Menu, Button, Row} from "antd";
import Link from "../Link";
import { StaticQuery, graphql } from "gatsby";
import BrewLogo from "../BrewLogo";

class TopNav extends React.Component {
    state = { open : false };

    openMobileNav = () => {
        this.setState({
            open : true,
        });
    };

    closeMobileNav = () => {
        this.setState({
            open : false,
        });
    };

    closeAndFollow = (e) => {
        e.preventDefault();
        this.setState({
            open : false,
        }, () => {
        });
    };

    render() {
        const isPartiallyActive = ({isPartiallyCurrent}) => {
            return isPartiallyCurrent
                ? { className: "activeLink" }
                : null
        };

        return (
            <React.Fragment>
                <Col xs={{span:12}} md={{span:0}} className="text-right">
                    <Button className="menu-trigger open" onClick={this.openMobileNav}>Menu <span className="icon" /></Button>
                </Col>
                <Col xs={{span:0}} md={{span:19}}>
                    <Menu mode="horizontal">
                        {this.props.links.map((link) => {
                            // add a trailing slash
                            if (link.url.substr(-1) !== '/') link.url += '/';
                            return <Menu.Item key={link.wordpress_id}><Link to={link.url} getProps={isPartiallyActive}>{link.title}</Link></Menu.Item>
                        })}
                    </Menu>
                </Col>
                <div className="mobile-navigation" open={this.state.open}>
                    <Row type="flex" align="bottom">
                        <Col xs={{span: 12}}>
                            <Link className="logo" to="/" width="120">
                                <BrewLogo />
                            </Link>
                        </Col>
                        <Col xs={{span:12}} className="text-right">
                            <Button className="menu-trigger close" onClick={this.closeMobileNav}>Close <span className="icon" /></Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{span:24}}>
                            <Menu mode="vertical">
                                {this.props.links.map((link) => {
                                    // add a trailing slash
                                    if (link.url.substr(-1) !== '/') link.url += '/';
                                    return <Menu.Item key={link.wordpress_id}><Link to={link.url} getProps={isPartiallyActive} >{link.title}</Link></Menu.Item>
                                })}
                            </Menu>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query NavItemLinks {
                allWordpressWpApiMenusMenusItems (filter: {slug: {eq:"header-menu"}}){
                    edges {
                        node {
                            id
                            slug
                            description
                            wordpress_id
                            items {
                                title
                                url
                                target
                                classes
                                order
                                wordpress_id
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => (
            <TopNav links={data.allWordpressWpApiMenusMenusItems.edges[0].node.items} />
        )}
    />
)

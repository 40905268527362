import React from 'react'
import './style.less';
import { Layout, Row, Col } from 'antd';
import BrewLogo from "../BrewLogo";
import Link from "../Link";
import TopNav from "../TopNav";

const { Header } = Layout;

class Head extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            prevScrollpos: 0,
            visible: true
        };
    }

    // Hide or show the menu.
    handleScroll = () => {
        const {prevScrollpos} = this.state;

        const currentScrollPos = window.pageYOffset;
        if (currentScrollPos > 0) {
            const visible = prevScrollpos > currentScrollPos;

            this.setState({
                prevScrollpos: currentScrollPos,
                visible
            });
        } else {
            this.setState({
                prevScrollpos: currentScrollPos,
                visible: true
            });
        }
    };

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    // Remove the event listener when the component is unmount.
    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }


    render(){

        return (
            <Header data-visible={this.state.visible}>
                <Row className="big-row" type="flex" align="bottom">
                    <Col xs={{span: 12}} md={{span: 5}}>
                        <Link className="logo" to="/" width="120">
                            <BrewLogo />
                        </Link>
                    </Col>
                    <TopNav />
                </Row>
            </Header>
        );
    }
}

export default Head

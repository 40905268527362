import React from 'react';
import './style.less';
import { Row, Col } from 'antd';

export default class PageTitle extends React.Component {
    render() {

        return (
            <div className="module_page_title">
                <Row className="big-row">
                    <Col xs={{span: 22}} md={{span: 18}} lg={{span: 12}}>
                        <h1>{this.props.title}</h1>
                    </Col>
                </Row>
            </div>
        );
    }
}

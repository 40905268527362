import React from 'react'
import { Form, Input, Button } from 'antd';
import addToMailchimp from 'gatsby-plugin-mailchimp';

class CampaignForm extends React.Component {

    constructor(props) {
        super(props);
        this.validateFormAndSend = this.validateFormAndSend.bind(this);
        this.subscribe = this.subscribe.bind(this);
        this.state = {
            complete: false,
            response: null
        };
    }

    validateFormAndSend(e){
        e.preventDefault();

        // Validate the form
        this.props.form.validateFields((err, values) => {
            if (!err) {
                return this.subscribe(values.email);
            }
        });
    }

    subscribe = async (email) => {
        const res = await addToMailchimp(email);
        this.setState({ response: res });
    };

    render(){
        const { getFieldDecorator } = this.props.form;

        if (this.state.response === null) {
            return (
                <Form layout="inline">

                    <Form.Item label="Email">
                        {getFieldDecorator('email', {
                            rules: [{type: "email", message: "Please enter a valid email address"}, {
                                required: true,
                                message: 'Please enter an email address'
                            }],
                            initialValue: ''
                        })(
                            <Input placeholder=""/>
                        )}
                    </Form.Item>

                    <Button className="arrow-link" type="primary" htmlType="button" onClick={this.validateFormAndSend}>Sign up</Button>

                </Form>
            );
        } else {
            return <p>{this.state.response.msg}</p>
        }
    }
}

export default Form.create()(CampaignForm);

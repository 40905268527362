import React from 'react';

export default class BrewLogo extends React.Component {
    render() {

        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="42" viewBox="0 0 120 42.34926">
                <title>brew-logo</title>
                <path className="b" d="M6.299.03294.126,3.54543a.24891.24891,0,0,0-.12581.21635V30.05834L0,30.066l.00019.0077V41.95453a.24892.24892,0,0,0,.24892.24892h6.173a.24893.24893,0,0,0,.24893-.24892v-.65586a.24872.24872,0,0,1,.35444-.22491A12.1394,12.1394,0,1,0,9.03191,18.33588a.24852.24852,0,0,0-.18357.24057v6.65579a.24845.24845,0,0,0,.38028.21107,5.46456,5.46456,0,1,1,2.90876,10.08927v0A5.4665,5.4665,0,0,1,6.671,30.08V.24929A.24892.24892,0,0,0,6.299.03294Z"/>
                <path className="r" d="M37.12792,17.91155l-.00036.00039a9.07417,9.07417,0,0,0-2.16156.26022.1323.1323,0,0,1-.16458-.128h0a.13225.13225,0,0,0-.13225-.13224H28.26281a.13224.13224,0,0,0-.13224.13224V42.07159a.13224.13224,0,0,0,.13224.13224h6.40636a.13225.13225,0,0,0,.13225-.13224v-15.526a.144.144,0,0,1,.00233-.026,2.3627,2.3627,0,0,1,3.90613-1.32956.13136.13136,0,0,0,.18066-.00551l4.53082-4.533a.13255.13255,0,0,0-.00051-.18818A8.97671,8.97671,0,0,0,37.12792,17.91155Z"/>
                <path className="e" d="M59.85375,35.38473c-.08411-.26326-.33534-.15314-.72985-.03629a7.4104,7.4104,0,0,1-5.22782-.34019,5.05249,5.05249,0,0,1-1.762-1.12492.36822.36822,0,0,1,.26038-.62967q5.92574.01449,11.8514.02975l3.26911.00718a.368.368,0,0,0,.36863-.36925c-.0023-1.07941.01023-2.16071-.02226-3.23918a12.14175,12.14175,0,0,0-24.24143-.62908,11.8445,11.8445,0,0,0,.00034,1.85158A12.30287,12.30287,0,0,0,48.52152,39.696c3.82,2.86573,8.509,3.25137,12.96519,1.91441a.35563.35563,0,0,0,.24585-.46591Zm-8.17959-8.69238c2.00127-2.68959,6.44317-2.90656,8.321.02343a.36852.36852,0,0,1-.3088.5685c-2.56854-.00875-5.14082-.00552-7.72447-.00552A.36677.36677,0,0,1,51.67416,26.69235Z"/>
                <path className="w" d="M86.2,31.54594m.23794-13.6436a.23819.23819,0,0,0-.23819.23819L86.2,31.54594a3.9934,3.9934,0,0,1-7.32717,2.191,3.97644,3.97644,0,0,1-.65616-1.71675.26058.26058,0,0,1-.0017-.03008V18.13727a.23493.23493,0,0,0-.23493-.23493H71.77972a.23808.23808,0,0,0-.23808.23806l-.00087,12.836a15.196,15.196,0,0,0,.10805,2.08806,10.54244,10.54244,0,0,0,1.23963,3.6669A10.66859,10.66859,0,0,0,89.39609,39.4126c.03562-.03261.07083-.09459.11709-.1122.06139-.02336.0976.0438.13377.08194a3.98074,3.98074,0,0,0,.36855.32432c.26439.21731.534.42809.81609.62205a10.65914,10.65914,0,0,0,16.53625-6.93781,13.03829,13.03829,0,0,0,.161-2.087l.00057-12.92827a.47324.47324,0,0,0-.47324-.47326h-5.7243a.47324.47324,0,0,0-.47324.47324l-.00023,13.101a4.60112,4.60112,0,0,1-.41775,1.87775,3.99064,3.99064,0,0,1-7.517-1.15183,6.9737,6.9737,0,0,1-.05274-1.03067l-.00006-13.03042a.2391.2391,0,0,0-.2391-.2391Z"/>
            <path className="dot" d="M115.43862,33.08128a4.56872,4.56872,0,0,0-4.56117,4.54935v.02585a4.56129,4.56129,0,1,0,4.56117-4.5752Z"/>
            </svg>
        );
    }
}

import React from 'react'
import './style.less';
import {Layout, Row, Col} from 'antd';
import Link from "../Link";
import SignupForm from "../SignupForm";
import {Icon} from 'react-fa'
import { StaticQuery, graphql } from "gatsby";
const {Footer} = Layout;

class Foot extends React.Component {
    state = {
        open : false,
        year : new Date().getFullYear()
    }

    showForm = (e) => {
        e.preventDefault();
        this.setState({
            open : true,
        });
    };

    openTrengo = () => {
        window.Trengo.Api.Widget.open('chat');
    }

    render(){
        return (
            <React.Fragment>
                <Footer>
                    <div className="bulletin-bar" open={this.state.open}>
                        <Row className="big-row">
                            <Col xs={{span: 24}}>
                                <h2><a href="##" onClick={this.showForm}>Upgrade your inbox</a></h2>
                                <SignupForm />
                            </Col>
                        </Row>
                    </div>
                    <div className="green-footer">
                        <Row className="big-row">
                            <Col xs={{span: 24}} lg={{span: 8}} xxl={{span: 6}}>
                                <h4 className="h3">We are brew.</h4>
                                <p className="copyright">&copy; {this.state.year}</p>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 12}} lg={{span: 8}} xxl={{span: 12}}>
                                <h4>Contact us</h4>
                                <Row>
                                    <Col xxl={{span:12}}>
                                        <p><strong>Quick enquiry?</strong></p>
                                        <p className="contact">
                                            <Link to={'/contact-us/'} className="arrow-link"> Contact us</Link>
                                        </p>
                                    </Col>
                                    <Col xxl={{span:12}}>
                                        <p><strong>Fancy a chat?</strong></p>
                                        <p className="contact">
                                            Instant message one of the team
                                            <br/>
                                            <a href="javascript:void(0)" onClick={this.openTrengo} className="arrow-link">Chat now</a>
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 12}} lg={{span: 8}} xxl={{span: 6}}>
                                <h4>Find us</h4>
                                <p className="address">Grosvenor House<br/>
                                    11 St Pauls Square<br/>
                                    Birmingham<br/>
                                    B3 1RB</p>
                                <div className="social-icons">
                                    <Link to="https://www.facebook.com/WeAreBrew/"><Icon size="5x" name="facebook" /></Link>  <Link to="https://twitter.com/hiwearebrew"><Icon size="5x" name="twitter" /></Link> <Link to="https://www.linkedin.com/company/3105504/"><Icon size="5x" name="linkedin" /></Link>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="black-footer">
                        <Row>
                            <Col className="text-center" xs={{span: 24}}>
                                {this.props.links.map((link) => {
                                    return <Link key={'f-link-key-'+link.wordpress_id} to={link.url}>{link.title}</Link>
                                })}
                            </Col>
                        </Row>
                    </div>
                </Footer>
            </React.Fragment>
        );
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query FooterNavItemLinks {
                allWordpressWpApiMenusMenusItems (filter: {slug: {eq:"footer-menu"}}){
                    edges {
                        node {
                            id
                            slug
                            description
                            wordpress_id
                            items {
                                title
                                url
                                target
                                classes
                                order
                                wordpress_id
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => (
            <Foot links={data.allWordpressWpApiMenusMenusItems.edges[0].node.items} />
        )}
    />
)

import React, {Component} from "react";
import { Helmet } from "react-helmet";
import { graphql, StaticQuery } from "gatsby";
class MetaData extends Component {

    getSchema(domain){
        const schemaOrgJSONLD = [
            {
                "@context": "http://schema.org",
                "@type": "LocalBusiness",
                name: "Brew Digital",
                legalName: "Brew Digital Ltd",
                logo: {
                    "@type": "ImageObject",
                    "name": "Brew Digital Logo",
                    "width": "7288",
                    "height": "2573",
                    "url": "https://www.wearebrew.co.uk/logo.png"
                },
                image: {
                    "@type": "ImageObject",
                    "name": "Brew Digital Logo",
                    "width": "7288",
                    "height": "2573",
                    "url": "https://www.wearebrew.co.uk/logo.png"
                },
                openingHours: 'Mo,Tu,We,Th,Fr 08:00-18:00',
                geo: {
                  "@type": "GeoCoordinates",
                  latitude: 52.487290,
                  longitude: -1.912284,
                },
                description: "We're a digital marketing agency in Birmingham, collaborating with people and brands. Our people, your brand, blended to become their digital best.",
                slogan: "Our people, your brand, blended to become their digital best.",
                address: {
                    "@type": "PostalAddress",
                    addressLocality: "Birmingham",
                    addressRegion: "West Midlands",
                    postalCode: "B9 4AA",
                    streetAddress: "414 The Greenhouse, Custard Factory, Birmingham, B9 4AA"
                },
                telephone: "441215170083",
                email: "hello@wearebrew.co.uk",
                url: domain,
                vatID: '',
                duns: 11673909,
                knowsAbout: [
                    'Digital Marketing',
                    'Web Development',
                    'App Development',
                    'Web Design',
                    'Brand Design',
                    'Content Writing',
                    'Social Media Strategy',
                    'Social Media Management',
                    'SEO',
                    'PPC and Paid Media',
                    'Email',
                    'Client Services'
                ],
                /*sameAs: [
                    'https://twitter.com/hiwearebrew',
                    'https://www.facebook.com/wearebrew',
                    'https://www.youtube.com/channel/UCJRy4g79P7k2O9AJOYCWccA',
                ]*/
            }
        ];

        // Add any custom schema objects being passed through from the page
        if (this.props.schema) {
            this.props.schema.map(item => {
                return schemaOrgJSONLD.push(item);
            });
        }
        return JSON.stringify(schemaOrgJSONLD);
    }

    render() {

        return (
            <StaticQuery
                query={graphql`
                  query SiteMetadata {
                    site {
                      siteMetadata {
                        title
                        twitter
                        domain
                      }
                    }
                  }
                `}
                render={({
                             site: {
                                 siteMetadata: { domain, title, twitter },
                             },
                         }) => (
                    <Helmet
                        encodeSpecialCharacters={false}
                        titleTemplate="%s | Brew"
                    >
                        <title>{this.props.title}</title>
                        <html lang="en" />
                        <meta name="description" content={this.props.description} />

                        { this.props.canonical !== undefined  ?  <link rel="canonical" href={domain + '/' + this.props.canonical} /> : null }

                        <meta name="docsearch:version" content="2.0" />
                        <meta
                            name="viewport"
                            content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
                        />

                        {/* Schema */}
                        <script type="application/ld+json">
                            {this.getSchema(domain)}
                        </script>

                        {/* OpenGraph */}
                        { this.props.og_title ? <meta property="og:url" content={domain + this.props.path} /> : null }
                        { this.props.og_title ? <meta property="og:type" content="website" /> : null }
                        { this.props.og_title ? <meta property="og:locale" content="en" /> : null }
                        { this.props.og_title ? <meta property="og:site_name" content={title} /> : null }
                        { this.props.og_title ? <meta property="og:title" content={this.props.og_title} /> : null }
                        { this.props.og_description  ? <meta property="og:description" content={this.props.og_description} /> : null }
                        { this.props.og_image  ? <meta property="og:image" content={domain + this.props.og_image} /> : null }
                        { this.props.og_image  ? <meta property="og:image:width" content="512" /> : null }
                        { this.props.og_image  ? <meta property="og:image:height" content="512" /> : null }

                        {/* Twitter Card */}
                        { this.props.twitter_title ? <meta name="twitter:site" content={twitter} /> : null }
                        { this.props.twitter_title ? <meta name="twitter:title" content={this.props.twitter_title} /> : null }
                        { this.props.twitter_title ? <meta name="twitter:description" content={this.props.twitter_description} /> : null }
                        { this.props.twitter_title ? <meta name="twitter:card" content={this.props.twitter_card_type} /> : null }
                        { this.props.twitter_image ? <meta name="twitter:image" content={domain + this.props.twitter_image} /> : null }

                    </Helmet>
                )}
            />
        )
    }
}

export default MetaData
